import { useEffect, useState } from 'react';

import {
  Autocomplete,
  autocompleteClasses,
  Box,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  inputAdornmentClasses,
  ListItem,
  ListItemAvatar,
  outlinedInputClasses,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import useSupabase from '../api/supabase';

import UserAvatar from './shared/UserAvatar';
import UsernameTitle from './shared/UsernameTitle';
import { CloseIcon, SearchIcon } from './icons';

export default function SearchAi({
  placeholder = 'Search Trenches',
  sx,
}: {
  placeholder?: string;
  sx?: SxProps;
}) {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const supabaseClient = useSupabase();

  useEffect(() => {
    setLoading(true);

    const getData = setTimeout(async () => {
      if (!query) {
        setSearchResults([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await supabaseClient
          .from('users_view')
          .select()
          .ilike('username', `${query}%`)
          .eq('user_type', 'creator');

        setSearchResults(response.data ?? []);
        setOpen(true);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [query]);

  function clearQuery() {
    setQuery('');
  }

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        position: 'sticky',
        top: 0,
        zIndex: 30,
        width: { xs: '240px', md: '480px' },
      }}
    >
      <Autocomplete
        id="creators-search"
        inputValue={query}
        onInputChange={(_, newInputValue) => {
          setQuery(newInputValue);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) =>
          option.username === value.username
        }
        options={searchResults}
        noOptionsText="No creators"
        autoHighlight
        getOptionLabel={(option) => option.username}
        loading={loading}
        sx={{
          [`& .${inputAdornmentClasses.root}`]: {
            mr: 0,
          },
          [`& .${autocompleteClasses.input}`]: {
            pl: 0,
          },
          [`&.${autocompleteClasses.hasPopupIcon} .${outlinedInputClasses.root}`]:
            {
              '&:hover': {
                border: 'none',
              },
              ...sx,
            },
        }}
        componentsProps={{
          paper: {
            sx: {
              borderRadius: 3,
              border: '1px solid #262829',
              backgroundColor: '#111314',
              mt: 2,
            },
          },
        }}
        ListboxProps={{
          sx: {
            maxHeight: 'calc(100vh - 120px)',
            p: 0,
          },
        }}
        renderOption={(props, user: any, { index }) => (
          <Box key={user.username}>
            <ListItem
              {...props}
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                '&:hover': {
                  background: '#262829',
                },
              }}
            >
              <ListItemAvatar
                sx={{
                  my: 2,
                }}
              >
                <UserAvatar
                  size={40}
                  name={user.username}
                  src={user.avatar}
                  walletAddress={user.wallet_address}
                />
              </ListItemAvatar>

              <Box>
                <UsernameTitle username={user.username} overlay />

                <Typography variant="caption" color="text.secondary">
                  {user.bio}
                </Typography>

                {user.isSubscribed && (
                  <Typography variant="caption" color="text.primary">
                    Subscribed
                  </Typography>
                )}
              </Box>
            </ListItem>

            {index < searchResults.length - 1 && <Divider />}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              borderRadius: 3,
              border: '1px solid #262829',
              '&:hover': {
                border: '1px solid #383B3D',
              },
            }}
            fullWidth
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                sx: {
                  '::placeholder': {
                    fontSize: '16px',
                  },
                },
              },
              endAdornment: (
                <>
                  {loading && (
                    <CircularProgress color="primary" size={20} sx={{ m: 2 }} />
                  )}

                  {query && (
                    <IconButton onClick={clearQuery}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {!isFocused && !query && (
                    <Icon sx={{ stroke: '#828282' }}>
                      <SearchIcon />
                    </Icon>
                  )}
                </InputAdornment>
              ),
              sx: {
                borderRadius: 3,
                color: '#828282',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              },
            }}
          />
        )}
      />
    </Box>
  );
}
