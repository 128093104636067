import { Box, Grid, Typography } from '@mui/material';
import { usePriceInUsd } from '../../../../hooks/usePriceInUsd';

interface IStatsItemProps {
  icon: React.ReactNode;
  title: string;
  value: number;
  isDollar?: boolean;
}

export function StatsItem({ icon, title, value, isDollar }: IStatsItemProps) {
  const { convertToUSD } = usePriceInUsd();

  return (
    <Grid item xs={4}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ width: '14px', height: '22px' }}>{icon}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#F0F5FA',
              lineHeight: '20px',
            }}
          >
            {isDollar ? `$ ${convertToUSD(value)}` : value}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '12px', md: '14px' },
              fontWeight: 300,
              color: '#9B9FA3',
              lineHeight: { xs: '15px', md: '17px' },
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
