import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useSupabase from '../supabase';
import { Trench } from '../types/getLeaderboard';

export function useGetTopTrenches({
  skip,
  num,
  enabled,
}: {
  skip: number;
  num: number;
  enabled?: boolean;
}) {
  const client = useSupabase();
  const queryKey = ['get_top_trenches', skip, num];

  const queryFn = async (): Promise<Trench[]> => {
    const { data, error } = await client.rpc('get_top_trenches', {
      skip,
      num,
    });
    if (error) throw new Error(error.message);
    return data as Trench[];
  };

  return useQuery<Trench[], Error>({
    queryKey,
    queryFn,
    enabled,
    keepPreviousData: true,
  } as UseQueryOptions<Trench[], Error>);
}
